import type { MenuData } from "~/types/menuData";

export async function useMenuData() {
  const nuxtApp = useNuxtApp();

  const initialized = useState<boolean>("useMenuData_initialized", () => false);
  const data = useState<MenuData | undefined>("useMenuData_data");

  await callOnce(async () => {
    if (!initialized.value) {
      initialized.value = true;
      data.value = await nuxtApp.$api("/api/menuData");
    }
  });

  return {
    data,
  };
}
